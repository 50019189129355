  <template>
  <div>
    <edit-car
      :is-edit-car-active.sync="isEditCarActive"
      :edit-car-id="editCarId"
      :user-id-prop="frontendUserId"
    />
    <!--  class="match-height" -->
    <b-row
      class="match-height"
    >
      <b-col
        cols="12"
        lg="4"
        md="12"
      >
        <b-card
          style="min-height:475px;"
        >
          <h3>
            <span
              v-if="user.customerType == 2"
            >
              <feather-icon
                icon="BriefcaseIcon"
                size="20"
              />&nbsp;
              {{ user.companyTitle }}
              <br>
            </span>
            <feather-icon
              icon="UserIcon"
              size="20"
            />&nbsp;
            {{ user.lastName }} {{ user.firstName }}
            <span
              v-if="user.customerType == 1"
            >
              (privat)
            </span>
            <span
              v-if="user.customerType == 2"
            >
              (Unternehmen)
            </span>
            <br>
            <br>
            <b-row>
              <b-col>
                <feather-icon
                  icon="MapPinIcon"
                  size="15"
                />
                Adresse
                <h2>{{ userAddress.street }}</h2>
                <h2>{{ userAddress.zip }} {{ userAddress.city }}</h2>
              </b-col>
            </b-row>
            <br>
            <div
              style="float:right; margin-top:5px; font-weight:normal; font-size:14px; "
            >
              <b-link
                :to="{ name: 'user-detail', params: { userId: user.id, bonusId:bonus.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                <feather-icon
                  icon="Edit2Icon"
                  size="14"
                />&nbsp;
                Edit
              </b-link>
            </div>
          </h3>
          <br><br>
          <b-row
            class="match-height"
          >
            <b-col
              cols="12"
              md="12"
            >
              <b-row>
                <b-col>
                  <feather-icon
                    icon="CalendarIcon"
                    size="15"
                  />
                  Von - Bis
                  <h2>{{ bonus.dateFrom | moment("DD.MM.YYYY") }} -  {{ bonus.dateTo | moment("DD.MM.YYYY") }}</h2>
                </b-col>
              </b-row>
              <br>
              <b-row>
                <b-col>
                  <feather-icon
                    icon="MapPinIcon"
                    size="15"
                  />
                  Adresse
                  <h2>{{ bonus.street }}</h2>
                  <h2>{{ bonus.zip }} {{ bonus.city }}</h2>
                </b-col>
              </b-row>
              <br>
              <b-row>
                <b-col>
                  <feather-icon
                    icon="BatteryChargingIcon"
                    size="15"
                  />
                  Typ
                  <h2>{{ mapConnector(bonus.connectorType) }}</h2>
                </b-col>
              </b-row>
              <br>
              <b-row>
                <b-col>
                  <feather-icon
                    icon="PowerIcon"
                    size="15"
                  />
                  Kann messen
                  <h2
                    v-if="bonus.flatRate===false"
                  >
                    Ja
                  </h2>
                  <h2
                    v-else-if="bonus.flatRate===true"
                  >
                    Nein
                  </h2>
                  <h2
                    v-else
                  >
                    N/A
                  </h2>
                </b-col>
              </b-row>
              <br>
              <b-row>
                <b-col>
                  <feather-icon
                    icon="LinkIcon"
                    size="15"
                  />
                  Partner
                  <h2>{{ partner }}</h2>
                </b-col>
              </b-row>
              <br>
              <b-button
                size="sm"
                variant="outline-primary"
                style="max-width:100px"
                @click="openEditSidebar(car.id)"
              >
                <feather-icon icon="Edit2Icon" />
                Edit
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        lg="8"
        md="12"
      >
        <b-card>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <h3><small>{{ car.openAiLastAttempt | moment("DD.MM.YYYY HH:mm") }}</small><br>KI Prüfung</h3>
              <span
                v-if="car.openAiConfirmedAddress==true"
                style="font-size: 20px"
              >
                <b-badge variant="light-primary">
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="23"
                    class="align-middle text-body"
                  />
                  Adresse
                </b-badge>
              </span>
              <span
                v-else
                style="font-size: 20px"
              >
                <b-badge variant="light-secondary">
                  <feather-icon
                    icon="XCircleIcon"
                    size="23"
                    class="align-middle text-body"
                  />
                  Adresse
                </b-badge>
              </span>
              <br>
              <span
                v-if="car.openAiConfirmedVin==true && car.stringMatch==true"
                style="font-size: 20px"
              >
                <b-badge variant="light-primary">
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="23"
                    class="align-middle text-body"
                  />
                  Fahrzeug-Identifizierungsnummer ( {{ car.openAiVin }} )
                </b-badge>
              </span>
              <span
                v-else-if="car.openAiConfirmedVin==true && car.stringMatch != true"
                style="font-size: 20px"
              >
                <b-badge variant="light-primary">
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="23"
                    class="align-middle text-body"
                  />
                  <span
                    v-if="!!car.openAiVin"
                  >
                    Fahrzeug-Identifizierungsnummer ( {{ car.openAiVin }} | {{ car.openAiVinMatch }}% )
                  </span>
                  <span
                    v-else
                  >
                    Fahrzeug-Identifizierungsnummer ( {{ car.openAiVinMatch }}% )
                  </span>
                </b-badge>
              </span>
              <span
                v-else
                style="font-size: 20px"
              >
                <b-badge variant="light-secondary">
                  <feather-icon
                    icon="XCircleIcon"
                    size="23"
                    class="align-middle text-body"
                  />
                  Fahrzeug-Identifizierungsnummer ( {{ car.openAiVin }} )
                </b-badge>
              </span>
              <span
                v-if="car.paperFormatHasStamp==true"
                style="font-size: 20px"
              >
                <br>
                <b-badge variant="light-primary">
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="23"
                    class="align-middle text-body"
                  />
                  Stampiglie
                </b-badge>
              </span>
              <br>
              <br>
              <b-badge variant="light-info">
                {{ car.openAiMotorType }}
              </b-badge>
              <br>
              <b-badge variant="light-info">
                {{ car.permitFormat }}
              </b-badge>
              <br><br>
              <h5>KI zusätzliche info</h5>
              KI Name/Nachname/Firmenname: {{ car.openAiFirstName }} {{ car.openAiLastCompanyName }}<br>
              KI Adresse: {{ car.openAiAddress }}<br>
              <span
                :style="compareDates(bonus.dateFrom ,car.openAiPermitValidFrom) ? 'color:red;' : ''"
              >
                Angemeldet seit: {{ car.openAiPermitValidFrom }}<br>
              </span>
              <div
                v-if="car.postProcessMessage"
              >
                <br>
                <h5>Post-Prozessierung ergebnis</h5>
                <b><span v-html="car.postProcessMessage"/></b>
              </div>
              <br>
              <b-button
                size="sm"
                variant="outline-primary"
                style="max-width:100px"
                @click="historyOpenAi(bonus.id); showOpenAIprocessModal();"
              >
                Ai Historie
              </b-button>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <h3>Bonus Status</h3>
              <br>
              <b-row
                v-for="(status) in bonusStatus"
                :key="status.key"
                style="margin-bottom:5px;"
              >
                <b-col
                  cols="12"
                  md="1"
                >
                  <feather-icon
                    v-if="status.finished"
                    icon="CheckCircleIcon"
                    size="25"
                    class="text-primary"
                  />
                  <feather-icon
                    v-if="!status.finished"
                    icon="CircleIcon"
                    size="25"
                  />
                </b-col>
                <b-col
                  v-if="status.finished"
                  cols="12"
                  md="11"
                >
                  <h4>{{ status.value }} ({{ status.date | moment("DD.MM.YYYY HH:mm") }} | {{ status.user }})</h4>
                </b-col>
                <b-col
                  v-if="!status.finished"
                  cols="12"
                  md="11"
                >
                  <h4>{{ status.value }}</h4>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <h3>Zulassungsschein ({{ bonus.vin }}, {{ getUseTypeLabel(bonus.useType) }})</h3>
              <br>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <span
                    v-for="(file) in files"
                    v-if="file.fileType=='permit_side_a' || file.fileType=='permit_side_b'"
                    :key="file.id"
                  >
                    <!-- modal listener v-b-modal.modal-previewImage -->
                    <b-button
                      variant="outline-secondary"
                      @click="previewImage(file.id, file.userId)"
                    >
                      <feather-icon icon="CameraIcon" />
                      {{ getFileTitle(file.fileType) }}
                    </b-button>
                    &nbsp;
                  </span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        lg="8"
        md="12"
      >
        <b-card>
          <h5>
            Backoffice Kommentar
          </h5>
          <b-form-textarea
            v-model="backofficeComment"
            placeholder="Kommentar"
            rows="5"
          />
          <br>
          <b-button
            size="sm"
            variant="primary"
            @click="updateComment()"
          >
            <feather-icon icon="SaveIcon" />
            Kommentar Speichern
          </b-button>
          <br>
          <br>
          <div v-html="backofficeComment"/>
        </b-card>
        <b-card>
          <h5>
            Partner Kundennummer, usw.
          </h5>
          <b-form-textarea
            v-model="backendPartnerComment"
            placeholder="Kommentar"
            rows="5"
          />
          <br>
          <b-button
            size="sm"
            variant="primary"
            @click="updatePartnerComment()"
          >
            <feather-icon icon="SaveIcon" />
            Speichern
          </b-button>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        lg="4"
        md="12"
      >
        <b-card>
          <h5>
            Status Log
          </h5>
          <app-timeline>
            <app-timeline-item
              v-for="(status) in bonusStatusTimeline"
              :key="'log_'+status.id"
              :title="getStatusLabel(status.status)"
              :subtitle="status.userName"
              :time="$moment(status.t0).format('DD.MM. YYYY HH:mm')"
              variant="info"
            />
          </app-timeline>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="modal-previewImage"
      title="Vorschau"
      ok-title="Schließen"
      ok-only
      size="xl"
    >
      <div
        v-if="loadingPreviewFile === true"
        style="text-align: center;"
      >
        <b-spinner /><br>
        Wird geladen ...
      </div>
      <div
        style="text-align: center; background-color:black;"
      >
        <b-img
          :src="previewFile"
          style="max-height: 85vh;"
          fluid
        />
      </div>
    </b-modal>
    <b-modal
      id="modal-openAIprocess"
      ref="openAIprocess-modal"
      title="AI prozessierung"
      ok-title="Schließen"
      ok-only
      size="xl"
    >
      <div
        v-if="openAIprocessActive === true"
        style="text-align: center;"
      >
        <b-spinner /><br>
        Wird geladen ...
      </div>
      <div
        v-if="openAIprocessActive === false"
      >
        <h4>Prompt: </h4>
        <pre>{{ openAIprocessData.prompt }}</pre>
        <h4>Response: </h4>
        <pre v-html="openAIprocessData.response" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BModal,
  BSpinner,
  BImg,
  BFormTextarea,
  BLink,
  BBadge,
} from 'bootstrap-vue'

import {
  onUnmounted,
  ref,
  computed,
  watch,
} from '@vue/composition-api'

import { base64StringToBlob } from 'blob-util'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import store from '@/store'
import bonusStoreModule from '../bonusStoreModule'

import router from '@/router'
import { bonusStatus as bonusEnum, connectorTypes } from '../../../../enums'

import EditCar from '../../sidebar/EditCar.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BModal,
    BSpinner,
    BImg,
    BFormTextarea,
    AppTimeline,
    AppTimelineItem,
    BLink,
    BBadge,
    EditCar,
  },
  methods: {
    showOpenAIprocessModal() {
      this.$refs['openAIprocess-modal'].show()
    },
  },
  setup() {
    const toast = useToast()
    const BONUS_APP_STORE_MODULE_NAME = 'app-bonus'

    // Register module
    if (!store.hasModule(BONUS_APP_STORE_MODULE_NAME)) store.registerModule(BONUS_APP_STORE_MODULE_NAME, bonusStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BONUS_APP_STORE_MODULE_NAME)) store.unregisterModule(BONUS_APP_STORE_MODULE_NAME)
    })

    const carUpdateddAt = computed(() => store.getters[`${BONUS_APP_STORE_MODULE_NAME}/carUpdateddAt`])

    const { bonusId } = router.currentRoute.params
    const address = ref({})
    const userAddress = ref({})
    const bonus = ref({})
    const car = ref({})
    const files = ref({})
    const sepa = ref({})
    const user = ref({})
    const partner = ref('')
    const backofficeComment = ref('')
    const backendPartnerComment = ref('')
    const bonusStatus = ref([])
    const bonusStatusTimeline = ref([])

    const isEditCarActive = ref(false)
    const editCarId = ref('')
    const frontendUserId = ref('')

    const previewFile = ref('')
    const loadingPreviewFile = ref(false)

    const fetchData = () => {
      store
        .dispatch(`${BONUS_APP_STORE_MODULE_NAME}/fetchBonusDetail`, { id: bonusId })
        .then(response => {
          address.value = response.data.address
          bonus.value = response.data.bonus
          car.value = response.data.car
          files.value = response.data.files
          sepa.value = response.data.sepa
          user.value = response.data.user
          partner.value = response.data.partner
          bonusStatusTimeline.value = response.data.bonusStatus
          userAddress.value = response.data.userAddress

          backofficeComment.value = response.data.bonus.backendComment
          backendPartnerComment.value = response.data.bonus.backendPartnerComment
          frontendUserId.value = user.value.id

          bonusStatus.value = []

          //  override end of season 2023 as not all have the data -- remove this after 2023 year change
          console.log(response.data.bonus)
          //  bonus.value.flatRate = null
          console.log('flat1', bonus.value.flatRate)
          if (response.data.bonus.flatRate === true) {
            bonus.value.flatRate = true
            console.log('flat2', bonus.value.flatRate)
          } else if (response.data.bonus.flatRate === false) {
            console.log('flat3', bonus.value.flatRate)
            if (response.data.bonus.chargingStationManufacturer != null && response.data.bonus.chargingStationManufacturer.length > 0) {
              console.log('flat4', bonus.value.flatRate)
              bonus.value.flatRate = false
            } else {
              bonus.value.flatRate = null
            }
          }

          //  bonus status
          Object.entries(bonusEnum).forEach(([key, value]) => {
            const tmpObject = {}
            tmpObject.key = parseInt(key, 10)
            tmpObject.value = value

            tmpObject.finished = false
            tmpObject.user = ''
            tmpObject.date = ''
            tmpObject.statusEnabled = false
            if (bonus.value.currentStatus >= parseInt(key, 10)) {
              //  tmpObject.finished = true
              tmpObject.statusEnabled = true
              for (let i = 0; i < bonusStatusTimeline.value.length; i += 1) {
                if (bonusStatusTimeline.value[i].status === parseInt(key, 10)) {
                  tmpObject.user = bonusStatusTimeline.value[i].userName
                  tmpObject.date = bonusStatusTimeline.value[i].t0
                  tmpObject.finished = true
                  break
                }
              }
            }

            if (tmpObject.statusEnabled === true && tmpObject.finished === true) {
              bonusStatus.value.push(tmpObject)
            }
            /*
            if (tmpObject.statusEnabled === false && tmpObject.finished === false) {
              bonusStatus.value.push(tmpObject)
            }
            */
          })
        })
        .catch(error => {
          console.log(error)
        })
    }

    const getFile = (fileId, userId) => {
      previewFile.value = ''
      loadingPreviewFile.value = true
      store.dispatch(`${BONUS_APP_STORE_MODULE_NAME}/getFile`, { fileId, userId })
        .then(response => {
          const blob = base64StringToBlob(response.data, response.headers['content-type'])
          const fileURL = URL.createObjectURL(blob)

          // const tab = window.open()
          // tab.location.href = fileURL
          window.open(fileURL)

          previewFile.value = fileURL
          loadingPreviewFile.value = false
        })
        .catch(() => {
          loadingPreviewFile.value = false
        })
    }

    const previewImage = (fileId, userId) => {
      getFile(fileId, userId)
    }

    const mapConnector = connectorId => {
      for (let i = 0; i < connectorTypes.length; i += 1) {
        if (connectorId === connectorTypes[i].value) {
          return connectorTypes[i].text
        }
      }
      return connectorId
    }

    const getFileTitle = type => {
      let title = ''
      if (type === 'permit_side_a') {
        title = 'Zulassungsschein Vorderseite vorschau'
      }

      if (type === 'permit_side_b') {
        title = 'Zulassungsschein Rückseite vorschau'
      }
      return title
    }

    const updateComment = () => {
      store.dispatch(`${BONUS_APP_STORE_MODULE_NAME}/saveComment`, { id: bonusId, comment: backofficeComment.value })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Erfolg',
              text: 'Kommentar gespeichert.',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          console.log('error')
        })
    }

    const updatePartnerComment = () => {
      store.dispatch(`${BONUS_APP_STORE_MODULE_NAME}/savePartnerComment`, { id: bonusId, comment: backendPartnerComment.value })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Erfolg',
              text: 'Kommentar gespeichert.',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          console.log('error')
        })
    }

    const getStatusLabel = listStatus => bonusEnum[listStatus]

    const openEditSidebar = id => {
      editCarId.value = id
      isEditCarActive.value = true
    }

    const getUseTypeLabel = type => {
      if (type === 1) {
        return 'Privatfahrzeug'
      }
      if (type === 2) {
        return 'Firmenwagen'
      }

      return ''
    }

    fetchData()
    watch(carUpdateddAt, (val, oldVal) => {
      if (val > oldVal) {
        fetchData()
      }
    })

    const compareDates = (date1, date2) => {
      if (date2 != null && date2.length > 0) {
        const dt1 = new Date(date1)
        const pattern = /(\d{2})\.(\d{2})\.(\d{4})/
        const dt2 = new Date(date2.replace(pattern, '$3-$2-$1'))

        if (dt1.getTime() < dt2.getTime()) {
          return true
        }
      }

      return false
    }

    /* AI HISTORY */
    const openAIprocessActive = ref(false)
    const openAIprocessData = ref({ prompt: '', response: '' })
    const historyOpenAi = bonusId => {
      openAIprocessData.value = { prompt: '', response: '' }
      openAIprocessActive.value = true

      store.dispatch(`${BONUS_APP_STORE_MODULE_NAME}/historyOpenAi`, { id: bonusId })
        .then(response => {
          openAIprocessData.value = response.data
          const responseArray = JSON.parse(response.data.response)
          responseArray.choices[0].message.content = responseArray.choices[0].message.content.replace(/(?:\r\n|\r|\n)/g, '<br>')
          openAIprocessData.value = {
            prompt: response.data.prompt,
            response: responseArray,
          }
          openAIprocessActive.value = false
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Fehler bei der Verbindung zum Server',
              icon: 'AlertTriangleIcon',
              variant: 'warning',
            },
          })
          openAIprocessActive.value = false
        })
    }

    return {
      bonusId,
      address,
      bonus,
      car,
      files,
      sepa,
      user,
      partner,
      backofficeComment,
      backendPartnerComment,
      bonusStatusTimeline,
      bonusStatus,
      userAddress,

      previewFile,
      loadingPreviewFile,
      isEditCarActive,
      editCarId,
      frontendUserId,

      openAIprocessActive,
      openAIprocessData,

      mapConnector,
      getFileTitle,
      previewImage,
      updateComment,
      getStatusLabel,
      openEditSidebar,
      getUseTypeLabel,
      updatePartnerComment,
      compareDates,
      historyOpenAi,
    }
  },
}
//
</script>
