import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getCarDetail(ctx, { userId, carId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/car/get-car-detail/${userId}/${carId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getFile(ctx, { fileId, userId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/file-repository/get-file/${fileId}/${userId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAddressList(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/address/get-user-address-list/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    update(ctx, {
      id, bonusId, carData,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/car/edit/${id}/${bonusId}`, carData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
